module services {
    export module logistics {
        export class containerService implements interfaces.logistics.IContainerService
        {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            delete(consignmentId?: number, manifestId?: number, containerId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Container/Delete", {
                    conId: consignmentId,
                    manId: manifestId,
                    containerId: containerId
                });
            }

            getContainerStowage(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Container/GetContainerStowage", {
                });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Container/Save", {
                });
            }

            getContainer(consignmentId?: number, manifestId?: number, containerId?: number): ng.resource.IResourceClass<interfaces.logistics.IContainer> {
                return this.$resource<interfaces.logistics.IContainer>(this.ENV.DSP_URL + "Container/GetContainer", {
                    containerId: containerId,
                    consignmentId: consignmentId,
                    manifestId: manifestId,
                }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            signOff(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Container/SignOff", {
                });
            }

            getManifestContainers(manId: number): ng.resource.IResourceClass<interfaces.logistics.IContainerDisplay> {
                return this.$resource<interfaces.logistics.IContainer>(this.ENV.DSP_URL + "Container/GetManifestContainers", {
                    manId: manId
                });
            }

            calculateDunnageWeight(containerId: number, manifestId?: number, consignmentId?: number): ng.resource.IResourceClass<ng.resource.IResource<{ value: number }>> {
                return this.$resource<ng.resource.IResource<{ value: number }>>(this.ENV.DSP_URL + "Container/CalculateDunnageWeight", {
                    containerId: containerId,
                    manifestId: manifestId,
                    consignmentId: consignmentId
                });
            }
        }
    }
    angular.module("app").service("containerService", services.logistics.containerService);
}